import LogService from '@/services/LogService';

const changeLang = {
  watch: {
    lang() {
      LogService.log('changed lang to', this.lang);
      this.$store.commit('CLEAR_STRINGS');
      this.init();
    },
  },
};

export default changeLang;
