import { callApi } from '@/api';
import store from '@/store';

const postOrderProduct = (data) => {
  const url = '/api/product';
  return callApi({ url, method: 'POST', data }, store);
};

const next = () => {
  //
};

export {
  postOrderProduct,
  next,
};
